@font-face {
  font-family: "Montserrat";
  src: url("/fonts/Montserrat-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("/fonts/Montserrat-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("/fonts/Montserrat-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

//https://developers.google.com/fonts/docs/material_icons
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/MaterialIconsRound-Regular.otf") format("opentype");
}

@font-face {
  font-family: "Material Icons Outlined";
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/MaterialIconsOutlined-Regular.otf") format("opentype");
}

body {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-style: normal;
  color: #000840; //NightSky
  font-size: 16px;
  margin: 0;
  padding: 0;
}
